<template>
  <div class="page-max page-start" style="flex-direction: column; max-width: 99vw; padding: 0 10px !important;">
    <div>
      <h2>ФИАЦ</h2>
    </div>
    <div class="list">
      <div class="row" style="padding-bottom: 5px">
        <div class="col-sm-4 col-xs-12 text-left">
          <div class="dataTables_length bs-select" id="userTypeSelector" style="width: 90%">
            <label>Показать <select name="dtBasicExample_length" aria-controls="dtBasicExample" class="custom-select custom-select-sm form-control form-control-sm" v-model="userType" :options="userTypes"  style="width: 100%">
              <option v-for="type in userTypes" v-bind:value="type.value" :key="type.value">
                {{ type.label }}
              </option>
            </select></label>
          </div>
        </div>
        <div class="col-sm-8 col-xs-12 text-right left-on-small">
          <div id="dtBasicExample_filter" class="dataTables_filter" >
            <label ><span style="float: left; padding-left: 10px">Искать:</span>
              <input type="search" class="form-control form-control-sm" placeholder="" aria-controls="dtBasicExample" v-model="filter" ></label>
          </div>
        </div>
      </div>

      <div style="overflow-x: auto; max-width: 98vw">
        <!--<md-table v-model="users" md-sort="lastName" md-sort-order="asc" md-card>-->
        <md-table v-model="filteredUsers" >
          <md-table-toolbar>
            <h1 class="md-title">Пользователи и кандидаты</h1>
          </md-table-toolbar>

          <md-table-row slot="md-table-row"  md-sort="lastName" md-sort-order="asc" slot-scope="{ item }">
            <!--<md-table-row slot="md-table-row" slot-scope="{ item }">-->
            <md-table-cell md-label="Фамилия" md-sort-by="lastName">{{ item.lastName }}</md-table-cell>
            <md-table-cell md-label="Имя" md-sort-by="firstName">{{ item.firstName }}</md-table-cell>
            <md-table-cell md-label="Отчество" md-sort-by="patronymic">{{ item.patronymic }}</md-table-cell>
            <md-table-cell md-label="Почта" md-sort-by="email">{{ item.email }}</md-table-cell>
            <md-table-cell md-label="Телефон" md-sort-by="phone">{{ item.phone }}</md-table-cell>
            <md-table-cell md-label="Роль">
              <div v-for="role in item.roles" :key="role.type">
                {{getText(role.type)}}
              </div>
            </md-table-cell>
            <md-table-cell md-label="Действия" >
              <div style="display: flex; gap: 20px; flex-wrap: wrap">
                <span v-if="item.activated" @click="alertx('Операция не поддерживается')" style="text-decoration: underline; cursor: pointer" class="text-danger">Удалить</span>
                <span v-if="item.activated" @click="reset(item)" style="text-decoration: underline; cursor: pointer" class="text-danger">Сбросить пароль</span>
                <span v-if="!item.activated" @click="approveUser(item)" style="text-decoration: underline; cursor: pointer">Активировать</span>
                <span v-if="!item.activated" @click="rejectUser(item)" style="text-decoration: underline; cursor: pointer">Отклонить</span>
                <span v-if="!item.activated" @click="checkLogs(item)" style="text-decoration: underline; cursor: pointer">Логи</span>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>


    </div>
  </div>
</template>

<script>
  import {getUsers} from "../api/user-controller";
  import {approveRegisterRequest, getRequests, rejectRegisterRequest} from "../api/request-controller";
  import logger from '../utils/logger'
  import {fieldSorter} from "../utils/compare";
  import {translate} from "../utils/translations";

  import {mapActions} from 'vuex'


  export default {
    name: "Users",
    data() {
      return {
        filter: '',
        users: [],
        paginatedUsers: [],
        userType: (this.$route.query && this.$route.query.type === 'requests') ? 'requests' : 'all',
        userTypes: [
          {value: 'all', label: 'Все'},
          {value: 'requests', label: 'Запросы'},
          {value: 'users', label: 'Пользователи'}
        ],
        pageLimits: [10, 25, 100],
        currentPageLimit: 10,
        demoUsers: [
          {id: 1, organization: 1, role: 1, name: 'Игорь', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101231212', email: 'ivanov.igor@noexistentemail.null', activated: true},
          {id: 2, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 3, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 4, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 5, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 6, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 7, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 8, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 9, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
        ]
      }
    },
    computed: {
      filteredUsers() {
        if (!this.filter || !this.filter.trim()) return this.users.filter(this.typeEqual)//this.users
        return this.users.filter(u => {
          return this.typeEqual(u) && Object.values(u).some(v => {
            if (v) {
              logger('v', v, typeof v === 'string', typeof v)
              if (typeof v === 'string') {
                return v.startsWith(this.filter)
              } else {
                return false
              }
            } else {
              return false
            }
          })
        })
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      checkLogs(userId) {
        this.$router.push('/logs/' + userId)
      },
      updatePagination() {
        return true
      },
      getText(text) {
        return translate(text)
      },
      reset(user) {

        logger('user', user)

        this.showInfoAlert('пока не поддерживается')
        // resetPassword(user.email ? user.email : null)
        //   .then((result) => {
        //     logger('result', result)
        //     this.showInfoAlert('пароль сброшен')
        //     return this.init()
        //   })
        //   .catch(e => this.showInfoAlert(e))
      },
      typeEqual(user) {
        switch (this.userType) {
          case 'users' : return user.activated
          case 'requests' : return !user.activated
          default: return true
        }
      },
      alertx(text) {
        this.showInfoAlert(text)
      },
      async rejectUser(user) {
        logger('user', user)
        rejectRegisterRequest(user.email ? user.email : null)
          .then((result) => {
            logger('result', result)
            return this.init()
          })
          .catch(e => this.showInfoAlert(e))
      },
      async approveUser(user) {
        logger('user', user)
        approveRegisterRequest(user.email ? user.email : null)
          .then((result) => {
            logger('result', result)
            return this.init()
          })
          .catch(e => this.showInfoAlert(e))
      },
      // async init() {
      //   let users = getUsers()
      //   let requests = getRequests()
      //   Promise.all([users, requests])
      //     .then(async results => {
      //       let userData = await users
      //       let requestData = await requests
      //       logger('results', results, 'users', userData.payload, 'requests', requestData.payload)
      //       let data = []
      //       userData.payload?.forEach(u => {
      //         Object.assign(u, splitFullname(u.fullName), {activated: true})
      //         data.push(u)
      //       })
      //       requestData.payload?.forEach(r => {
      //         Object.assign(r, splitFullname(r.fullName))
      //         data.push(r)
      //       })
      //       data.sort(fieldSorter(['lastName', 'name', 'patronymic', 'username', 'created']))
      //       logger('data', data)
      //       this.users = data
      //     })
      //     .catch(e => {
      //       this.showInfoAlert(e)
      //     })
      // },
      async init() {
        logger('init route', this.$route.query, this.$route.query.type, this.$route.query.type === 'requests', this.userType)
        if (this.$route.query && this.$route.query.type === 'requests ') {
          this.userType = 'requests'
          this.userType.$touch
        }
        logger('init route', this.$route.query, this.$route.query.type, this.userType)
        let users = getUsers()
        let requests = getRequests()
        return Promise.all([users, requests])
          .then(async results => {
            let userData = await users
            let requestData = await requests
            logger('results', results, 'users', userData.payload, 'requests', requestData.payload)
            let data = []
            userData.payload?.forEach(u => {
              Object.assign(u, {activated: true})
              data.push(u)
            })
            requestData.payload?.forEach(r => {
              // Object.assign(r, splitFullname(r.fullName))
              data.push(r)
            })
            data.sort(fieldSorter(['lastName', 'firstName', 'patronymic', 'username', 'created']))
            logger('data', data)
            this.users = data
          })
          .catch(e => {
            this.showInfoAlert(e)
          })
      }
    },
    mounted() {
      this.init()
    }
  }
</script>

<style scoped>
  .list {
    padding: 20px;
  }
  .list-user {
    padding: 5px;
    height: 40px;
    background-color: aliceblue;
  }

  .list-user + .list-user {
    background-color: lightsteelblue;
  }

  @media screen and (max-width: 768px) {
    .left-on-small {
      text-align: left;
      float: left;
    }
  }

  tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .03);
  }
  body tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .2);
  }
</style>
