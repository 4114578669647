import { render, staticRenderFns } from "./Users2.vue?vue&type=template&id=40fb1dba&scoped=true"
import script from "./Users2.vue?vue&type=script&lang=js"
export * from "./Users2.vue?vue&type=script&lang=js"
import style0 from "./Users2.vue?vue&type=style&index=0&id=40fb1dba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fb1dba",
  null
  
)

export default component.exports